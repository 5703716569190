































import {Component, Vue, Watch} from 'vue-property-decorator';
import {State, Getter, Action} from 'vuex-class';
import _ from 'lodash';
import {Product, App, Proposal, Cms} from '@/models';

import ProductView from './Product/Index.vue';
import PolicyCoverageBox from '@/views/auth/Portal/PortalStepperItems/Configure/PolicyCoverageBox.vue';
import Util from '@/utils/Util';
import GAUtil from '@/utils/GAUtil';
import EventBus from '@/common/EventBus';
import { IQuotationProduct, IShiftProductData } from '@/interfaces';
import QuoteProduct from '@/models/QuoteProduct';
import GeneralInformation from '@/views/auth/Portal/PortalStepperItems/GeneralInformation.vue';

@Component({
	name: 'ProductConfig',
	components: {
    PolicyCoverageBox,
		ProductView,
    GeneralInformation,
	},
})
export default class ProductConfig extends Vue {
  @State private app!: App;
  @State private proposal!: Proposal;
  @State private quotation!: IQuotationProduct;
  @State private cms!: Cms;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => QuoteProduct[];
  @Getter('quotation/getConfigurationFactHashes') private getConfigurationFactHashes!: () => any;
  @Action('quotation/populateBasicInfoToAllQuote') private populateBasicInfoToAllQuote: any;

  private isDisabled: boolean = false;
  private showTooltip = false;
  private firstLoad: boolean = true;

  public validate() {
    let validationResult = true;
    _.forEach(this.$refs.product, (p: any) => {
      validationResult = validationResult && p.validate();
    });
    return validationResult;
  }

  get hashes() {
    return this.getConfigurationFactHashes();
  }

  get allFacts() {
    let facts: any[] = [];
    this.products.forEach((p: any) => {
       facts = [...facts, ...p.facts.facts];
    });

    return facts;
  }

  @Watch('hashes')
  private hashUpdate() {
    this.$nextTick(() => {
      for (const hash in this.hashes) {
        if (hash) {
          if (this.hashes[hash].length > 1) {
            const style = document.createElement('style');
            style.innerHTML = '.' + hash + ' { pointer-events: none; opacity: 0.5; } .' + hash + '-first { pointer-events: auto; opacity: 1; }';
            document.getElementsByTagName('head')[0].appendChild(style);
          }
          const all = document.querySelectorAll('.' + hash);
          if (all.length) {
            all[0].parentElement!.classList.remove('hover-info');
            all[0].classList.add(hash + '-first');
            for (let i = 1; i < all.length; i++) {
              all[i].classList.remove(hash + '-first');
              all[i].parentElement!.classList.add('hover-info');
            }
          }
        }
      }
      });
  }

  get products() {
    if (this.firstLoad && Object.keys(this.getSortedQuotationProduct()).length) {
      setTimeout(() => {
        GAUtil.ODLogSelectedProducts();
      }, (2000)); // set timeout due to product state reactivity
      this.firstLoad = false;
    }
    return this.getSortedQuotationProduct();
  }

  @Watch('products')
  private productsUpdated() {
    this.$global.enterAnimation();
  }

  // Products that is not in renewal lifecycle and not in SEPARATE_COVERAGE_PERIOD config
  get filteredProducts() {
    return _.filter(this.products, (p: IShiftProductData) => !Util.isSeparateCoveragePeriod(p.code) && !_.values(_.get(this.app, 'renewalResponse.renewalIds', {})).includes(p.id));
  }

  get isCopyCoverageDate() {
    return this.app.copyCoverageDate;
  }

  get showDatesSection() {
    return _.get(this.app, 'showDatesSection', false) && !_.get(this.app, 'isRenewal');
  }

  get productIcons() {
    const productIcons = {};
    _.forEach(this.cms.products, (product: any) => {
      productIcons[product.code] = product.fields.iconInverted;
    });
    return productIcons;
  }

  private onScroll() {
    this.showTooltip = false;
  }

  private onInfoBtnClick(content: any) {
    this.$emit('onInfoBtnClick', content);
  }

  private isPivotCoverage(product: IShiftProductData, index: number) {
    if (Util.isSeparateCoveragePeriod(product.code)) {
      return false;
    }
    return this.filteredProducts.length > 1 && _.get(this.filteredProducts, '[0].code') === product.code;
  }

  private isCoverageDisabled(product: IShiftProductData, index: number) {
    return (!this.isPivotCoverage(product, index) && (this.filteredProducts.length > 1) &&  this.isCopyCoverageDate) || (this.showDatesSection);
  }

  private disableCoverage() {
    // this.isDisabled = true;
  }

  private enableCoverage() {
    // this.isDisabled = false;
  }

  private activated() {
    this.populateBasicInfoToAllQuote();
  }
}
