var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-wrapper"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":"","mb-2":""}},[(!_vm.isStartDateDisabled)?_c('v-menu',{ref:"dpMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(on1){return [_c('v-text-field',_vm._g({class:['date', 'required', 'mr-3', {'mobile': _vm.$vuetify.breakpoint.xsOnly}],style:({
          'border-color': _vm.$colors.border,
          'color': _vm.$colors.fontsPrimary
          }),attrs:{"value":_vm.getStartDateFormatted,"readonly":"","label":_vm.$t('proposal.configuration.coverage.startDate'),"error":_vm.$hasError(_vm.getUIDstart),"hint":_vm.$hasError(_vm.getUIDstart) ? _vm.$errorMsg(_vm.getUIDstart) : '',"persistent-hint":"","hide-details":!_vm.$hasError(_vm.getUIDstart),"outline":"","append-icon":"mdi-calendar"},on:{"click:append":function($event){_vm.dpMenu = true}}},on1.on))]}}],null,false,3140522428),model:{value:(_vm.dpMenu),callback:function ($$v) {_vm.dpMenu=$$v},expression:"dpMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.startDate,"color":_vm.$colors.accent,"allowed-dates":_vm.isDateAllowed,"disabled":_vm.isStartDateDisabled,"readonly":_vm.isStartDateDisabled},on:{"input":_vm.onDateInput}})],1):_vm._e(),(_vm.isStartDateDisabled)?_c('v-text-field',{class:['date', 'required', 'mr-3', {'mobile': _vm.$vuetify.breakpoint.xsOnly}],style:({
          'border-color': _vm.$colors.border,
          'color': _vm.$colors.fontsPrimary,
          'opacity': 0.5
          }),attrs:{"value":_vm.getStartDateFormatted,"readonly":"","disabled":"","label":_vm.$t('proposal.configuration.coverage.startDate'),"hint":_vm.$hasError(_vm.getUIDstart) ? _vm.$errorMsg(_vm.getUIDstart) : '',"persistent-hint":"","hide-details":!_vm.$hasError(_vm.getUIDstart),"outline":"","append-icon":"mdi-calendar"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-text-field',{class:['date', 'required', 'mr-3', {'mobile': _vm.$vuetify.breakpoint.xsOnly}],style:({
          'border-color': _vm.$colors.border,
          'color': _vm.$colors.fontsPrimary,
          'opacity': 0.5
          }),attrs:{"value":_vm.getEndDateFormatted,"readonly":"","disabled":"","label":_vm.$t('proposal.configuration.coverage.endDate'),"hint":_vm.$hasError(_vm.getUIDend) ? _vm.$errorMsg(_vm.getUIDend) : '',"persistent-hint":"","hide-details":!_vm.$hasError(_vm.getUIDend),"outline":"","append-icon":"mdi-calendar"}})],1)],1),(_vm.isPivot && _vm.showPivot)?_c('v-layout',{staticClass:"fact-holder mr-3 mb-3"},[_c('v-flex',{attrs:{"shrink":"","mr-1":""}},[_c('v-layout',[_c('v-flex',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"name":"copyCoverageDates","color":_vm.$colors.secondary,"hide-details":true},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}})],1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('v-flex',{staticClass:"pt-1"},[_c('div',{staticClass:"description pa-0 ma-0",style:({color: _vm.$colors.fontsSecondary}),attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('proposal.configuration.coverage.copyTooltip'))+" ")])]):_vm._e()],1)],1),_c('v-flex',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"description pl-0 pt-2 pr-0 pb-0 ma-0",style:({color: _vm.$colors.fontsSecondary}),attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('proposal.configuration.coverage.copyTooltip'))+" ")]):_vm._e()])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }