


























































































































































import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {State, Getter} from 'vuex-class';
import _ from 'lodash';
import EventBus from '@/common/EventBus';
import {Proposal, Cms, App, Product} from '@/models';
import Util from '@/utils/Util';
import ProductEngineUtils from '@/utils/ProductEngineUtils';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import { IQuotationProduct, IQuotedPrice, IQuotation } from '@/interfaces';

@Component({
  name: 'ConfigSummary',
  components: {
    FactTooltip
  },
})
export default class ConfigSummary extends Vue {
  @State private cms!: Cms;
  @State private app!: App;
  @State private quotation!: IQuotation;
  @Prop() private fixed?: boolean;
  @Getter('quotation/getQuotedPrices') private getQuotedPrices!: () => IQuotedPrice[] ;
  @Getter('quotation/getTotalQuotedPrice') private getTotalQuotedPrice!: () => number ;

  private open: boolean = false;
  private bottom: boolean = false;
  private totalPrice: number | string = '-';

  private created() {
    EventBus.$on('openPricebox', () => {
      this.open = true;
    });
    EventBus.$on('updated:products', _.debounce(() => {
      EventBus.$emit('close-loading-dialog');
    }, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS));
  }

  get products() {
    return this.getQuotedPrices();
  }

  get grandTotalPrice() {
    const price: number = this.getTotalQuotedPrice();
    if (this.totalPrice !== (price || '-')) {
      this.totalPrice = price || '-';
      EventBus.$emit('priceChange');
    }
    return price;
  }

  private onScroll() {
    const scrollBottomOffset = (_.get(window, 'pageYOffset', 0) as number) + (_.get(this.$refs, 'summary.$el.clientHeight', 0) as number) + 372;
    const fullHeight = (_.get(document, 'body.clientHeight', 0) as number);
    this.bottom =  scrollBottomOffset > fullHeight;
  }

  private close() {
    this.open = false;
  }

  private coverageDates(product: Product) {
    const quoted = _.find(this.getQuotedPrices(), (q) => q.productDetail.id === product.id);
    return (quoted && quoted.coverageDateRange) || '';
  }

  private getTotalProductPrice(product: IQuotedPrice) {
    if (product.isReviewRequired) {
      return this.$t('proposal.product.reviewRequired');
    }
    return product.totalProductPrice > 0 ? product.totalProductPrice : '-';
  }

  private getCurrentPrice(product: IQuotedPrice) {
    if (product.isReviewRequired) {
      return this.$t('proposal.product.reviewRequired');
    }
    return product.currentPrice > 0 ? product.currentPrice : '-';
  }

}
