








































































































































import QuoteProduct from '@/models/QuoteProduct';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import utils from '@/views/auth/Portal/PortalStepperItems/Utils';

import FactText from '@/components/common/form/fact/FactText.vue';
import FactDate from '@/components/common/form/fact/FactDate.vue';
import FactOption from '@/components/common/form/fact/FactOption.vue';
import FactMultilineText from '@/components/common/form/fact/FactMultilineText.vue';
import FactNumber from '@/components/common/form/fact/FactNumber.vue';
import FactSelection from '@/components/common/form/fact/FactSelection.vue';
import FactList from '@/components/common/form/fact/FactList.vue';
import FactLabel from '@/components/common/form/fact/FactLabel.vue';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import FactAddressAutoComplete from '@/components/common/form/fact/FactAddressAutoComplete.vue';
import FactMultiSelect from '@/components/common/form/fact/FactMultiSelect.vue';
import CustomMultiBoolean from '@/components/common/form/fact/CustomMultiBoolean.vue';
import { FactService } from '@/services/FactService';
import config from '@/config';
import { IFactUpdatePayload, IMultiFactUpdatePayload } from '@/interfaces';
import _ from 'lodash';
import { Proposal } from '@/models';

const CUSTOM_MODEL_FACTS = 'CUSTOM_MODEL_FACTS';

@Component({
	name: 'GeneralInformation',
  inject: ['$validator'],
  components: {
    FactList,
    FactLabel,
    FactTooltip,
    FactSelection,
    FactOption,
    FactDate,
    FactText,
    FactMultilineText,
    FactMultiSelect,
    FactNumber,
    FactAddressAutoComplete,
    CustomMultiBoolean
  },
})
export default class GenericConfig extends Vue {
  @State private app: any;
  @State private proposal!: Proposal;
  @Prop() private step!: string;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => QuoteProduct[];
  @Action('quotation/updateFact') private updateFact!: (payload: IFactUpdatePayload) => void;
  @Action('quotation/updateMultiFact') private updateMultiFact!: (payload: IMultiFactUpdatePayload) => void;

  private configs: any = null;
  private genericFactIds: string[] = [];
  private STEP_CONFIGURATION = 'configuration';
  private STEP_COVERAGE = 'coverage';
  private STEP_VERIFICATION = 'verification';

  get logo() {
    return '/coverhub-portal/images/general-info-icon.svg';
  }

  get allFacts() {
    let facts: any[] = [];

    if (this.getSortedQuotationProduct().length > 0) {
      // since this is the general information, just take facts from the first quote,
      // otherwise will get duplicated items.
      facts = [...this.getSortedQuotationProduct()[0].customAllFacts];
    }

    return utils.customModeler(facts, _.get(this.app.config.shiftMappings, CUSTOM_MODEL_FACTS, {}));
  }

  get groupedGenericFacts() {
    const grouping = _.get(this.configs, 'grouping', null);
    const groupingKeys = grouping && Object.keys(grouping);
    const responseObject = {};
    let index = 1;

    if (groupingKeys) {
      groupingKeys.forEach((gKey: string) => {
        const factDatas = grouping[gKey].facts.map((factId: string) => this.allFacts.find((fact: any) => fact.id === factId));
        if (!factDatas.every((fact: any) => fact === undefined)) {
          responseObject[gKey] = grouping[gKey];
          responseObject[gKey].index = index;
          responseObject[gKey].factDatas = factDatas;
          index++;
        }
      });
    }

    return _.isEmpty(responseObject) ? null : responseObject;
  }

  private getConfigs() {
    this.configs = utils.getGenericFactsConfig(this.step);
  }

  private getConfigByType(componentType: string, options: any = [], id: string = '') {
    if (componentType === 'GROUP') {
      return;
    }
    if (componentType === 'BOOLEAN') {
      return 'FactOption';
    }
    if (componentType === 'LIST' && (options && options.length) >= parseInt(config.layout.multiselectLimit, 10)) {
      return 'FactMultiSelect';
    }
    const addressAutoComplete = _.keys(_.get(this.app.config.shiftMappings, 'ADDRESS_AUTO_COMPLETE', []));
    if (_.find(addressAutoComplete, (key) => id.endsWith(key))) {
      componentType = 'ADDRESS_AUTO_COMPLETE';
    }
    return FactService.getFactType(componentType);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private selectedAddress(productId: string, originatingFactId: string, address: any) {
      const fields = utils.getAddressFields(originatingFactId, address, _.get(this.app.config.shiftMappings, 'ADDRESS_AUTO_COMPLETE', {}));
      const factsDataToUpdate: any[] = [];

      fields.forEach((fact) => {
        let val = fact.val[0];
        // handler for list search val / name
        let realFact: any = null;
        _.forEach(_.values(this.allFacts), (f) => {
          if (f.id === fact.id || f.id.endsWith(fact.id)) {
            realFact = f;
            return false;
          }
        });
        if (realFact && realFact.formatType === 'list') {
          const option = _.find(realFact.options, (opt) => _.values(fact.val).includes(opt.value) || _.values(fact.val).includes(opt.name));
          if (option) {
            val = option.value;
          }
        }
        if (realFact) {
          factsDataToUpdate.push({
            factID: realFact.id,
            factValue: val
          });
        }
      });
      this.proposal.products.forEach((product) => {
        this.updateMultiFact({
          productID: product.id,
          productCode: product.code,
          facts: factsDataToUpdate
        });
      });
    }

  private updateFn(factData: any, factValue: any, autofill: boolean = false) {
    this.updateFact({
      productID: factData._productID,
      fact: factData,
      value: factValue,
      vm: this,
      autofill
    });
  }

  private getFactLevel(fact) {
    if (fact._level === undefined) {
      const id = _.get(fact, 'id', '');
      fact._level = (id.match(/\./g) || '').length;
    }
    return fact._level;
  }

  private created() {
    this.getConfigs();
  }

}
