

































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import {State, Getter, Action} from 'vuex-class';
import FactPhoneNumber from '@/components/common/form/fact/FactPhoneNumber.vue';
import MarkdownText from '@/components/common/form/MarkdownText.vue';
import Util from '@/utils/Util';
import GAUtil from '@/utils/GAUtil';
import _ from 'lodash';
import EventBus from '@/common/EventBus';
import { IQuotation } from '@/interfaces';
import Illustration from '@/components/common/Illustration.vue';

@Component({
  name: 'BasicInfo',
  components: {
    FactPhoneNumber,
    MarkdownText,
    Illustration
  },
  $_veeValidate: {
    validator: 'new'
  },
})
export default class BasicInfo extends Vue {
  @Prop() private next: any;
  @Prop() private img: any;
  @State private app: any;
  @State private cms: any;
  @State private proposal: any;
  @State private quotation!: IQuotation;
  @Getter('app/getRenewalResponse') private getRenewalResponse: any;
  @Action('proposal/setBasicInfo') private setBasicInfo: any;
  @Action('app/setValue') private setValue: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;

  get basicInfoFormFields() {
    setTimeout(() => this.$global.enterAnimation(), 500);
    return _.get(this.proposal, 'basicInfo', []);
  }

  private basicInfoFields: any = [];
  private phoneObj: any = {};
  private pardotId = '';
  private lasttabindex = 0;

  private factValues = {};

  public validate(this: any) {
    this.$validator.validate().then((result: any) => {
      if (result) {
        this.sendToCRM();
        GAUtil.ODLogUserData();
        this.$global.leaveAnimation();
        this.next();
      } else {
        this.$emit('error', this.xxErrors);
      }
    });
  }

  private getClass(field) {
    if (field.colspan === 1 && field.type !== 'checkbox' && field.type !== 'infoText') {
      return this.$vuetify.breakpoint.width <= 1024 ? 'sm6' : 'sm5';
    }
    if (field.type === 'checkbox' || field.type === ' infoText') {
      return this.$vuetify.breakpoint.width  > 1024 ? 'sm10' : '';
    }
  }

  private prefillCountry() {
    if (!this.phoneObj.country) {
      this.phoneObj.country = _.get(this.app, 'detectedCountry');
    }
  }

  private activated() {
    this.$global.enterAnimation();
    this.$nextTick(() => {
      this.tabindexes();
    });
    Util.gaLogPageView(this, `/basicinfo+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
    });
  }

  private created() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.prefillCountry();
    this.$global.enterAnimation();
    this.$nextTick(() => {
      this.tabindexes();
    });
  }

  private deactivated() {
    EventBus.$emit('stepper-busy');
  }

  get isRenewal() {
    return !!_.get(this.app, 'isRenewal');
  }

  private tabindexes() {
    const list = document.querySelectorAll('input[tabindex]');
    for (let index = 0; index < list.length; index++) {
      (list[index] as HTMLInputElement).tabIndex = index + 1;
    }
    this.lasttabindex = list.length;
  }

  private async sendToCRM() {
    if (this.quotation.products) {
      const addon = {};
      const addonKeys = ['opt_out'];
      _.forEach(this.basicInfoFormFields, (field: any) => {
        if (addonKeys.includes(field.pardotId)) {
          addon[field.pardotId] = field.value || !!field.value;
        }
      });
      _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_BASIC_INFO, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(this.pardotId, addon)
      .then(() => {
        this.setPardotAvailability(true);
      })
      .catch(() => {
        this.setPardotAvailability(false);
      });
    }
  }

  private onInput(event: any, field: any) {
    this.setValue({code: 'dirty', value: true});
    const value = typeof event === 'string' ? event.trim() : event;
    field.value = value;
    if (field.pardotId) {
      this.pardotId = value;
    }
    _.forEach(field.shiftFactIds, (shiftFactId) => {
      this.factValues[shiftFactId] = field.value;
    });
  }

  private isInfoText(field: any) {
    if (field.type === 'infoText') {
      return true;
    }
    return false;
  }

  private isPhoneType(field: any) {
    for (const f of field.shiftFactIds) {
      if (f && f.match(Util.MOBILE_ID_SUFFIX)) {
        return true;
      }
    }
    return false;
  }

  private onPhoneInput(data: any, field: any) {
    this.phoneObj = data;
    this.onInput(data.number, field);
  }

  private isVisibleForRenewal(field) {
    return !(this.app.renewalResponse.renewalIds && field.pardotId === Util.ASSOCIATION_ID);
  }

  private isTextFieldVisible(field) {
    return field.type === 'textField' &&
            !this.isPhoneType(field) &&
            this.isVisibleForRenewal(field);
  }
}
