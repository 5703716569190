

















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter, State} from 'vuex-class';
import {Proposal, App, Product} from '@/models';

import _ from 'lodash';
import PolicyCoverage from './PolicyCoverage.vue';
import moment from 'moment';
import ProductEngineUtils from '../../../../../utils/ProductEngineUtils';
import Util from '../../../../../utils/Util';
import EventBus from '../../../../../common/EventBus';
import { ISetPolicyStartDatePayload, IQuotation } from '@/interfaces';

@Component({
  name: 'PolicyCoverageBox',
  components: {
    PolicyCoverage
  }
})
export default class extends Vue {
  @State private proposal!: Proposal;
  @State private quotation!: IQuotation;
  @State private app!: App;
  @Action('app/setValue') private setValue: any;
  @Action('quotation/setValue') private setPolicyDate!: (payload: ISetPolicyStartDatePayload) => void;
  @Getter('cms/getProductContentByProduct') private getProductContentByProduct: any;
  @Action('quotation/setPolicyDateForAllQuotation') private setPolicyDateForAllQuotation!: (policyStartDate: string) => void;

  private options: any;
  private content: any;

  private minDate: string = '';
  private maxDate: string = '';
  private productCoverage: {policyStartDate, policyEndDate} = {policyStartDate: '', policyEndDate: ''};

  public validate() {
    let validationResult = true;
    _.forEach(this.$refs.components, (comp: any) => {
      validationResult = validationResult && comp.validate();
    });
    return validationResult;
  }

  get icon() {
    return '/coverhub-portal/images/coverage-period.svg';
  }

  get firstProduct() {
    return _.get(this.quotation, 'products[0]');
  }

  private async created() {
    this.content = this.getProductContentByProduct(this.firstProduct.id);
    this.options = _.get(this.content, 'options');
    this.minDate = moment().startOf('day').toISOString();
    this.maxDate = moment().startOf('day').add(60, 'days').toISOString();
    this.onCoverageStartDateStrInput(moment(this.proposal.coverageStartDate).format('YYYY-MM-DD'));
  }

  private onCoverageStartDateStrInput(value: string) {
    this.setPolicyDate({productID: this.firstProduct.id, date: value });
  }

  private onPolicyCoverageErrorChange(value: boolean) {
    EventBus.$emit('policyCoverageError', value);
  }

  private copyCoverageDateToAll(value) {
    this.setValue({code: 'copyCoverageDate', value: !!value});
    if (value) {
      this.setPolicyDateForAllQuotation(this.productCoverage.policyStartDate);
      this.$emit('disableCoverage');
    } else {
      this.$emit('enableCoverage');
    }
  }

  private hasRequiredError(errors, factId) {
   return  _.some(errors, (err) => {
     return err.error.code === 1 && _.includes(err.error.relatedFacts, factId);
    });
  }
}
