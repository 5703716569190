
























































































































import {Component, Vue, Prop} from 'vue-property-decorator';

import _ from 'lodash';
import Util from '@/utils/Util';
import moment from 'moment';
import ProductEngineUtils from '@/utils/ProductEngineUtils';
import {State, Action, Getter} from 'vuex-class';
import EventBus from '@/common/EventBus';
import { IApp, IQuotationProduct, ICMS } from '@/interfaces';

@Component({
  name: 'PolicyCoverage',
  $_veeValidate: {
    validator: 'new'
  },
})
export default class extends Vue {
  @State private app!: IApp;
  @State private cms!: ICMS;

  @Prop() private minDate!: string;
  @Prop() private maxDate!: string;
  @Prop() private startDate!: string;
  @Prop() private endDate!: string;
  @Prop() private product!: any;
  @Prop() private isPivot!: boolean;
  @Prop() private showPivot!: boolean;
  @Prop() private isDisabled!: boolean;
  @Action('quotation/setPolicyDateForAllQuotation') private setPolicyDateForAllQuotation!: (policyStartDate: string) => void;
  @Action('app/setValue') private setValue: any;
  @Getter('quotation/getShiftResultByProductID') private getShiftResultByProductID!: (productID: string) => any ;
  private dpMenu = false;
  // private modelValue;

  get modelValue() {
    // return !_.get(this.app, 'renewalResponse.renewalIds', []).includes(this.product.id) || this.app.copyCoverageDate;
    return this.app.copyCoverageDate;
  }

  set modelValue(value: boolean) {
    this.setValue({code: 'copyCoverageDate', value});
    if (value) {
      this.setPolicyDateForAllQuotation(this.startDate);
    }
  }

  get getUIDstart() {
    return _.get(this.product, 'code', null) && `${this.product.code}:coverage.start`;
  }

  get getUIDend() {
    return _.get(this.product, 'code', null) && `${this.product.code}:coverage.end`;
  }

  get isStartDateDisabled() {
    return (this.app.isRenewal
      && _.values(_.get(this.app, 'renewalResponse.renewalIds', {})).includes(this.product.id)
      && !_.values(_.get(this.app, 'renewalResponse.nonRenewalIds', {})).includes(this.product.id)) || this.isDisabled;
  }

  get getStartDateFormatted() {
    return moment(this.startDate).format(_.get(this.cms, 'theme.dateFormat') || 'DD/MM/YYYY');
  }

  get getEndDateFormatted() {
    return moment(this.endDate).format(_.get(this.cms, 'theme.dateFormat') || 'DD/MM/YYYY');
  }

  private isDateAllowed(date) {
    const same = moment(date).isSame(moment(this.getCorrectMinimumStartDate));
    return same || moment(date).isBetween(this.getCorrectMinimumStartDate, this.getCorrectMaximumStartDate);
  }

  private onDateInput(value: string) {
    if (!this.isDisabled) {
      this.$emit('update', value);
      this.dpMenu = false;
      this.validate();
    }
    if (this.isPivot) {
      this.$emit('copyDates', this.modelValue);
    }
  }

  private created() {
    // this.modelValue = !_.get(this.app, 'renewalResponse.renewalIds', []).includes(this.product.id);
    this.validate();
    if (this.isDisabled && this.modelValue) {
      // this.$emit('copyDates', this.modelValue);
    }

    // EventBus.$on('product:added', () => {
    //   if (this.isPivot) {
    //     this.$emit('copyDates', this.modelValue);
    //   }
    // }).$on('product:removed', () => {
    //   if (this.isPivot) {
    //     this.$emit('copyDates', this.modelValue);
    //   }
    // });


    EventBus.$on('savedProposal-expired-ok', () => {
      if (moment().isAfter(this.product?.coverageStartDate)) {
        const today = moment().format('YYYY-MM-DD');
        this.onDateInput(today);
      }
    });
  }

  private deactivated() {
    EventBus.$off('savedProposal-expired-ok');
  }

  private validate(this: any) {
    let result = true;
    this.$resetErrors();
    const shiftResult: any[] = this.getShiftResultByProductID(this.product, []);
    if (!_.isEmpty(shiftResult)) {
      _.forEach(shiftResult, (err: any) => {
        if (_.includes([2, 3, 4], err.error.code)) {
          const suffix = _.get(err, 'error.message', '').match(/'END'/) ? 'end' : 'start';
          this.$addError(`${err.productId}:coverage.` + suffix, this.$t(`validations.shift.${err.error.message}`));
          result = false;
        }
      });
    }
    this.$emit('error', !result);
    return result;
  }

  get getTermPeriod() {
    return _.get(this.product, 'customDescription.description.product.policyPeriod', false);
  }

  get getCorrectMinimumStartDate() {
    if (this.getTermPeriod) {
      const minStartDate = this.getTermPeriod.minStartDate;
      const startDate = moment(minStartDate);
      return moment(this.minDate).diff(startDate, 'days') < 0 ? startDate.startOf('day').toISOString() : this.minDate;
    } else {
      return this.minDate;
    }
  }

  get getCorrectMaximumStartDate() {
    if (this.getTermPeriod) {
      const maxStartDate = this.getTermPeriod.maxStartDate;
      return moment(maxStartDate).startOf('day').toISOString();
    } else {
      return this.maxDate;
    }
  }
}
