



























import {Component, Vue, Prop} from 'vue-property-decorator';
import {State, Action, Getter} from 'vuex-class';
import ProductConfig from './ProductConfig/Index.vue';
import ConfigSummary from './ConfigSummary.vue';
import GeneralInformation from '@/views/auth/Portal/PortalStepperItems/GeneralInformation.vue';
import AddCoverage from './AddCoverage.vue';
import {Proposal} from '@/models';
import _ from 'lodash';
import Util from '@/utils/Util';
import EventBus from '@/common/EventBus';
import FactDate from '@/components/common/form/fact/FactDate.vue';
import { USE_POLICY } from '@/utils/ProductEngineUtils';
import { IQuotation, IShiftProductData, IRawProducts } from '@/interfaces';
import GAUtil from '@/utils/GAUtil';
import Illustration from '@/components/common/Illustration.vue';

const FIRST_NON_TECHNICAL_ERROR = 100;

@Component({
  name: 'Configure',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    FactDate,
    ProductConfig,
    ConfigSummary,
    GeneralInformation,
    AddCoverage,
    Illustration
  }
})
export default class Configure extends Vue {
  @State private proposal!: Proposal;
  @State private quotation!: IQuotation;
  @State private app: any;
  @State private cms: any;
  @State private products!: IRawProducts;
  @Prop() private next: any;
  @Prop() private img: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;
  @Action('quotation/setSynonyms') private setSynonyms!: () => void;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => IShiftProductData[];

  private content: any = [];
  private policyCoverageError = {};
  private percentError = false;
  private headerVisibility = true;

  public validate(this: any) {
    const view = this;
    if (view.quotation.products.length > 0) {
      return false;
    }
    this.$resetErrors();
    this.$validator.validate()
      .then(async (result: any) => {
        const productConfig = this.$refs.productConfig;
        const shiftValidationResult = (productConfig as any).validate();
        if ((result === true || result.valid === true) && shiftValidationResult && !this.hasCoverageError() && !this.percentError) {
          this.sendToCRM();
          await GAUtil.ODLogProductsConfigured();
          this.$global.leaveAnimation();
          view.next();
        } else {
          this.$emit('error', this.xxErrors);
        }
      });
  }

  private onVisibleChanged(this: any, isVisible: any, entry: any) {
    this.headerVisibility = isVisible;
  }

  private compareErrorAndField(errorId, fieldId) {
    const fieldErrorFormat = fieldId.replace(/-/g, ':').replace(/_/g, '.').replace(':', '-');
    return errorId === fieldErrorFormat;
  }

  private compareErrorAndFieldPercent(errorId, fieldId) {
    return this.compareErrorAndField(errorId.replace(/\.percent$/, ''), fieldId);
  }

  private created() {
    EventBus.$on('policyCoverageError', (code, err) => {
      this.policyCoverageError[code] = err;
    });
    this.setSynonyms();
  }

  private activated() {
    this.$global.enterAnimation();
    Util.gaLogPageView(this, `/configure+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
    });
  }

  private deactivated() {
    EventBus.$emit('stepper-busy');
  }

  private hasCoverageError() {
    for (const code in this.policyCoverageError) {
      if (this.policyCoverageError.hasOwnProperty(code) && this.policyCoverageError[code]) {
        return true;
      }
    }
    return false;
  }

  private onInfoBtnClick(content: any) {
    this.content = content;
    EventBus.$emit('open-product-detail', content);
  }

  private async sendToCRM() {
    _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_CONFIGURATION, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)()
    .then(() => {
      this.setPardotAvailability(true);
    })
    .catch(() => {
      this.setPardotAvailability(false);
    });
  }

}
